<template>
  <div>
    <el-table
      :height="tableHeight"
      :data="displayDatas"
      tooltip-effect="dark"
      stripe
      style="width: 100%"
    >
      <el-table-column type="index" width="50" />
      <el-table-column label="产品">
        <template slot-scope="scope">
          <div class="xa-cell">
            <div
              @click="onPriview(scope.row.logo_url)"
              class="img-wrap"
              :style="{
                backgroundImage: 'url(' + scope.row.logo_url + ')'
              }"
            ></div>
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="onDelProduct(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="xa-cell xa-txt-placeholder">
      <div class="xa-flex">已选：商品（{{ datas.length }}）</div>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totoPid"
        :current-page="pid + 1"
        @current-change="onChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import calcTableHeightMixin from '../mixins/calcTableHeight'
export default {
  mixins: [calcTableHeightMixin],
  props: {
    datas: Array
  },
  data() {
    return {
      pid: 0,
      pSize: 10
    }
  },
  computed: {
    totoPid() {
      return Math.ceil(this.datas.length / this.pSize)
    },
    displayDatas() {
      return this.datas.slice(
        this.pid * this.pSize,
        (this.pid + 1) * this.pSize
      )
    }
  },
  watch: {
    datas() {
      if (this.datas.length / this.pSize <= this.pid) {
        this.pid = Math.max(0, this.pid - 1)
      }
    }
  },
  methods: {
    onChangePage(index) {
      this.pid = index - 1
    },
    onDelProduct(product) {
      const index = this.datas.indexOf(product)
      this.datas.splice(index, 1)
    }
  }
}
</script>
