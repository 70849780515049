export default {
  props: {
    calcHeight: {
      type: Boolean,
      default: false,
    },
    calcRows: {
      type: [Number, String],
      default: 5,
    },
    height: {
      type: [Number, String],
      default: 360,
    },
  },
  computed: {
    tableHeight() {
      if ('datas' in this === false) {
        window.console.warn(
          'mixins:calcTableHeight：需要指定传递的数据集合是datas'
        )
        return this.height
      }
      if (this.calcHeight) {
        if (this.datas.length === 0) {
          return 61 + 57
        }
        return 61 + Math.min(this.calcRows, this.datas.length) * 57
      } else {
        return this.height
      }
    },
  },
}
